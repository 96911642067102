<template>
  <v-col
    cols="12"
    sm="4"
  >
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
    >
      <v-text-field
        v-model="inputValue"
        :type="type"
        :placeholder="placeholder"
        :error-messages="errors"
        :disabled="disabled"
        @input="$emit('input', $event)"
      >
        <template v-slot:label>
          {{ label }}
          <span
            v-if="required"
            class="red--text"
          >
            *
          </span>
        </template>
      </v-text-field>
    </validation-provider>
  </v-col>  
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: "InputText",
    components: {
      ValidationProvider,
    },
    data: () => ({
      value: '',
    }),
    props: {
      type: {
        type: String,
        default: 'text',
      },
      rules: {
        type: String,
        default: "",
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
        default: "",
      },
      required: {
        type: Boolean,
      },
      defaultValue: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isLowerCase: {
        type: Boolean,
        default: false,
      },
    },
    created () {
      this.value = this.defaultValue;
    },
    computed: {
      inputValue: {
        get () {
          return this.value;
        },
        set (val) {
          this.value = (this.isLowerCase) ? val.toLowerCase() : val;
        },
      },
    },
  }
</script>

<template>
  <div :class="{'d-flex flex-column': isMobile}">
    <span
      class="text-overline osg-black--text"
      v-if="value"
    >
      {{ title }}
    </span>
    <span
      class="text-body-2 osg-black--text mt-2 mb-4"
      v-if="value">
      {{ value }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Field',
    props: {
      title: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
  };
</script>

<template>
  <div>
    <div class="header">
      <PrescriptionHeader />
    </div>
    <v-container>
      <v-alert
        v-show="nonAssociatedProducts"
        color="error"
        border="left"
        class="osg-alert text-body-2"
        icon="mdi-alert"
        transition="scroll-x-reverse-transition"
        elevation="6"
        colored-border
      >
        <span>{{ $t('error.nonAssociatedProducts') }}</span>
        <ul>
          <li 
            v-for="(product, index) in nonAssociatedProducts"
            :key="index"
          >
          {{product}}
          </li>
        </ul>
      </v-alert>
      <v-row
        align="center"
        justify="center"
      >
        <InputText
          :label="$t('provider')"
          :defaultValue="provider.providerName"
          disabled
          class="pr-md-5 mt-2"
        />
        <v-col class="d-flex justify-center justify-md-end justify-sm-end justify-lg-end pr-0" >
          <span class="text-body-2 osg-gray--text">
            {{ $t('dispensing.recipeCode') }}
          </span>
          <span
            class="text-body-2
                  mx-4
                  font-weight-black
                  text-uppercase
                  error--text"
          >
            {{ prescription.code }}
          </span>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="mt-4"
      >
        <v-col cols="12">
          <h5 class="text-h5 secondary--text requested-order__message-confirmed">
            {{ $t(requestedOrderMessage.title) }}
          </h5>
        </v-col>
        <v-col cols="12" sm="6" md="6" px-0 mx-0>
          <p class="text-center">
            {{ $t(requestedOrderMessage.body) }} 
            <a 
              class="text-decoration-underline" 
              :href="requestedOrderMessage.url"
              target="_blank">{{ requestedOrderMessage.clickableLabel }}</a>
          </p>
        </v-col>
      </v-row>
      <Products/>
      <v-row class="mt-6" v-if="provider.hasForm">
        <v-col cols="12" sm="6" md="6">
          <div class="d-flex flex-column">
            <h3 class="text-subtitle-1 secondary--text py-4" >
              {{ $t('dispensing.contact') }}
            </h3>
            <Field
              v-if="fullName"
              :title="$t('form.fullName')"
              :value="fullName"
            />
            <Field
              v-if="phoneNumber"
              :title="$t('form.phoneNumber')"
              :value="phoneNumber"
            />
            <Field
              v-if="email"
              :title="$t('form.email')"
              :value="email"
            />
            <Field
              v-if="nif"
              :title="$t('form.nif')"
              :value="nif"
            />
            <Field
              v-if="paymentName"
              :title="$t('form.paymentMethod')"
              :value="paymentName"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" v-if="address">
          <div class="d-flex flex-column">
            <h3 class="text-subtitle-1 secondary--text py-sm-4 py-md-4 py-lg-4 py-1" >
              {{ $t('dispensing.address') }}
            </h3>
            <Field :value="address" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import PrescriptionHeader from "@/components/general/PrescriptionHeader.vue";
  import InputText from '@/components/forms/InputText.vue';
  import Products from "@/components/requested-order/Products.vue";
  import Field from "@/components/requested-order/Field.vue";

  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    name: "RequestedOrder",
    components: {
      PrescriptionHeader,
      InputText,
      Products,
      Field,
    },
    data: () => ({
      fullName: null,
      phoneNumber: null,
      email: null,
      nif: null,
      paymentName: null,
      address: null,
      country: null,
    }),    
    mounted () {
      if (this.prescriptionDeliveryLog) {
        this.fullName = [this.prescriptionDeliveryLog.givenNames, this.prescriptionDeliveryLog.surnames].join(' ')
        this.phoneNumber = this.prescriptionDeliveryLog.phoneNumber;
        this.email = this.prescriptionDeliveryLog.emailAddress;
        this.nif = this.prescriptionDeliveryLog.taxIdentificationNumber;
        this.paymentName = this.prescriptionDeliveryLog.paymentName;
        this.address = this.prescriptionDeliveryLog.address;
  
      }
    },
    computed: {
      ...prescriptionModule.mapGetters(['prescription', 'provider', 'prescriptionDeliveryLog','requestedOrderMessage','nonAssociatedProducts']),
    }
};
</script>

<style lang="scss" scoped>
  .header {
    background-color: #f9f9f9 !important;
  }

  .requested-order {

    &__message {
      &-confirmed {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-description {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }

  .osg-alert{
    background-color: #f2f2f2;
  }
</style>



<template>
  <div>
    <RequestedOrder/>
  </div>
</template>

<script>
  import RequestedOrder from "@/components/requested-order/RequestedOrder.vue";

  export default {
    components: {
      RequestedOrder
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
  };
</script>

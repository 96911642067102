<template>
  <section :class="{'mt-n4': isMobile}">
    <h5
      class="secondary--text"
      :class="{'text-h6 mt-4': isMobile, 'text-subtitle-1': !isMobile}"
    >
      {{ $t('dispensing.product') }}
    </h5>
    <v-simple-table
      dense
      light
    >
      <template v-slot:default>
        <tbody>
          <tr
            v-for="product in prescriptionDeliveryLog.products"
            :key="product.productName"
            class="ma-16"
          >
            <td class="pl-5 pr-5 py-5 products__quantity">
              {{ product.quantity }}
            </td>
            <td class="pl-0">{{ product.productName }}</td>
          </tr>
          <div
            class="prescription-table__line--gray"
          />
        </tbody>
      </template>
    </v-simple-table>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    name: 'Products',
    computed: {
      ...prescriptionModule.mapGetters(['prescriptionDeliveryLog']),
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
  };
</script>

<style lang="scss" scoped>
  .products {
    margin: 10px 0px;

    &__quantity {
      width: 1px;
    }

    &__line {
      height: 8px;
      background-color: #FF726D;

      &--gray {
        background-color: #e0e0e0;
      }
    }
  }
</style>
